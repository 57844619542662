import * as React from "react"
import Subscribe from "../../components/subscribe"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const HarveyJudi = () => (
  <Layout siteTitle={"Harvey and Judi's Story"}>

    {/* top banner */}
    <div className="relative bg-indigo-800">
      <div className="!absolute inset-0">
        <StaticImage
          className="h-full w-full object-cover"
          src="../../images/HarveyOnTheBalcony.jpg"
          alt="Harvey on the balcony"
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Harvey and Judi's Story</h1>
        <p className="mt-6 font-light max-w-3xl text-xl text-indigo-100">
        WiN was founded by Judi Nudelman to honor the memory of her husband, respected neuroscientist Harvey Nudelman, who died of a rare neurological disease in 2018
        </p>
      </div>
    </div>

    {/* about Harvey */}
    <div className="overflow-hidden bg-white">
      <div className="relative mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
        <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">
          <div>
            <h2 className="text-lg font-semibold text-indigo-600">In Memoriam</h2>
            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">About Harvey Nudelman</h3>
          </div>
        </div>
        <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="relative lg:col-start-2 lg:row-start-1">
            <svg
              className="absolute top-0 right-0 -mt-20 -mr-20 hidden lg:block"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="de316486-4a29-4312-bdfc-fbce2132a2c1"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
            </svg>
            <div className="relative mx-auto max-w-prose text-base lg:max-w-none">
              <figure>
                <div className="!aspect-w-12 !aspect-h-7 lg:!aspect-none">
                  <StaticImage
                    className="rounded-lg object-cover object-center shadow-lg"
                    src="../../images/HarveyOnThePhone.jpg"
                    alt="Harvey taking birthday wishes from family and friends in far away cities"
                  />
                </div>
                <figcaption className="mt-3 flex text-sm text-gray-500">
                  <span className="ml-2">Harvey taking birthday wishes from family and friends in far away cities</span>
                </figcaption>
              </figure>
            </div>
          </div>
          <div className="mt-8 lg:mt-0">
            <div className="mx-auto max-w-prose text-base lg:max-w-none">
              <p className="text-lg text-gray-500">
              Harvey was born in St. Louis, MO, in 1939, graduated from Soldan High School in 1957, and earned a B.S. in Engineering Physics from Washington University, an M.S. in Physics at Iowa State University, and a PhD in Biomedical Engineering and Neurophysiology at the University of Illinois Medical School in Chicago. 
              </p>
            </div>
            <div className="prose prose-indigo mx-auto mt-5 text-gray-500 lg:col-start-1 lg:row-start-1 lg:max-w-none">
              <p>
              In 1970, Harvey and Judi moved to Houston, TX, for a postdoctoral fellowship in Bioengineering at Rice University. He was on faculty in Psychiatry at UT Medical School in Houston in the 1970s and Neurology at Baylor College of Medicine from 1979 to 1994. Harvey zealously applied mathematics to further medical research. He and Judi moved to Austin, TX, after he retired from academics in 1994.
              </p>
              <p>
              Harvey's passion and support for the education of all inspired the creation of WiN in the months following his death. His legacy lives on through the WiNterns who will become leaders in neuroscience and neurology as well as mentors and role models for future generations of girls.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* pull quote */}
    <div className="bg-white pt-16 lg:py-24">
      <div className="bg-indigo-600 pb-16 lg:relative lg:z-10 lg:pb-0">
        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8">
          <div className="relative lg:-my-8">
            <div aria-hidden="true" className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden" />
            <div className="mx-auto max-w-md px-6 sm:max-w-3xl lg:h-full lg:p-0">
              <div className="!aspect-w-10 !aspect-h-6 overflow-hidden rounded-xl shadow-xl sm:!aspect-w-16 sm:!aspect-h-7 lg:!aspect-none lg:!h-full">
                <StaticImage
                  className="object-cover lg:h-full lg:w-full"
                  src="../../images/HarveyAndJudy.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="mt-12 lg:col-span-2 lg:m-0 lg:pl-8">
            <div className="mx-auto max-w-md px-6 sm:max-w-2xl lg:max-w-none lg:px-0 lg:py-20">
              <blockquote>
                <div>
                  <svg
                    className="h-12 w-12 text-white opacity-25"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="mt-6 text-2xl font-medium text-white">
                    As a scientist, teacher, and mentor, Harvey was loved and respected by his students, colleagues, and friends. 
                  </p>
                </div>
                <footer className="mt-6">
                  <p className="text-base font-medium text-white">Judi Nudelman</p>
                  <p className="text-base font-medium text-indigo-100">Founder, WiN</p>
                </footer>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Why WiN video */}
      <div className="relative bg-white">
      
        <main className="lg:relative">
          <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
            <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <span className="block xl:inline">What is my WHY</span>{' '}
                <span className="block text-indigo-600 xl:inline">for founding WiN?</span>
              </h1>
              <p className="mx-auto mt-3 max-w-md text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Judi Nudelman shares the story of her relationship with Harvey Nudelman, why his struggles with a rare neurological disease inspired her to found WiN, and the career experiences that made her so passionate about advocating for sustained diversity in STEM fields.
              </p>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <a
                    href="https://donate.neurowomen.org/donate"
                    className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:py-4 md:px-10 md:text-lg"
                  >
                    Donate Today
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="relative h-64 w-full sm:h-72 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
          <iframe
            className="absolute inset-0 h-full w-full object-cover"
            src="https://drive.google.com/file/d/1JVQ0s0a-S811uFbRNe9tL5WzJ2zzhBW7/preview"
            allow="autoplay"
            title="What is my why for WiN?"
          ></iframe>
          </div>
        </main>
        </div>

    {/* email subscribe */}
    <div className="bg-white">
    <Subscribe />
    </div>

  </Layout>
)

export const Head = () => <Seo title="Harvey and Judi's Story" />

export default HarveyJudi